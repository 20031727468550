import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'

// Images
import student from '~images/support-man.svg'
import tflLogo from '~images/tfl_logo.png'

const TechieForLife = () => {
  const img = useStaticQuery(graphql`
    query {
      bgimg: file(relativePath: { eq: "techie_for_life_work.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <>
      <BackgroundImage Tag="div"
        fluid={img.bgimg.childImageSharp.fluid}
      >
        <div className="container space-2">
          <div className="row">
            <div className="col-xl-6 col-lg-9 mb-9 mb-md-0">

              {/* TFL */}
              <div className="position-relative gradient-half-primary-v4 shadow-primary-lg text-white rounded z-index-2 p-7">

                <span className="d-block">
                  <img className="align-top" src={tflLogo} aria-hidden="true" alt="" /><br />
                  <span className="align-top display-4 font-weight-semi-bold"> Techie For Life</span>
                  <span className="d-block">
                    <p className="text-white">QVR is one of the only SaaS companies who employs high percentages of neuro a-typical web developers  and software designers.</p>
                    <p className="text-white">Thus, our product is built for the consumers of the human service industry by those who understand that industry in a profound way.</p>
                    <p className="text-white">TFL students and graduates desire to give back to their less fortunate peers by building the software that makes their life easier, reduces their staff turnover and generates real outcome for real people. TFL and QVR’s collaborative effort are truly industry leading. Our outcomes are just as compelling as our story.<br /><a href="http://techieforlife.com" target="_blank">Read More</a>
                    </p>
                  </span>
                </span>

                <hr className="opacity-md my-4" />

                <div className="mb-5">
                  <p className="text-light">A-typical thinkers make up a minimum of 20% of QVR employees and interns.</p>
                </div>

                <Link className="btn btn-white btn-pill transition-3d-hover mb-2" to="/demo">
                  Demo Our Software
                <span className="fas fa-arrow-right text-primary font-size-1 ml-2"></span>
                </Link>

                {/* SVG Shape */}
                <div className="position-absolute bottom-0 right-0 w-100 max-width-27 z-index-n1">
                  <figure className="ie-abstract-shapes-11">
                    <img className="img-fluid rounded-bottom" src={student} alt="Techie For Life Student" />
                  </figure>
                </div>
                {/* End SVG Shape */}
              </div>
              {/* End TFL */}
            </div>

          </div>
        </div>
      </BackgroundImage>
    </>
  )
}

export default TechieForLife
