import React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

// Components
import Layout from '~components/layout'
import TeamDetailSlider from '~components/team-detail-slider'
import TechieForLife from '~components/techie-for-life'

// Images
import team from '~images/qvr_team1.svg'
import devices from '~images/devices-1.svg'
import logoTeal from '~images/qvr_logo_teal_black.svg'
import circle from '~images/half-circle-2QVR.svg'
import circles from '~images/circles.svg'
import circlesOrange from '~images/circles-orange.svg'
import img1 from '~images/img1.jpg'


const AboutQvr = () => {
  const img = useStaticQuery(graphql`
    query {
      bluelogo: file(relativePath: { eq: "logo_bluestep.png"}) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <main id="content" role="main">

        {/* Hero Section */}
        <div id="SVGHero" className="position-relative bg-off-white overflow-hidden">
          <div className="container position-relative z-index-2 space-top-2 space-bottom-3 space-top-md-4">
            <div className="row align-items-lg-center">
              <div className="col-lg-5 mb-11 mb-lg-0">
                {/* Info */}
                <div className="mb-6">
                  <h1 className="text-white font-weight-semi-bold mb-3">
                    <strong>Our <span className="text-warning">Mission:</span></strong>
                  </h1>
                  <p className="text-white-70">
                    Enable our customers to focus on enhancing the lives of their clients by providing software that is easy to use, reduces or eliminates common errors, automates repetitive tasks, &amp; improves staff preformance.
                  </p>
                </div>

                <Link className="btn btn-white btn-wide btn-pill text-primary shadow-soft transition-3d-hover mb-2 mb-sm-0 mr-4" to="/demo">Contact Sales</Link>
                <Link className="btn btn-indigo btn-wide btn-pill transition-3d-hover mb-2 mb-sm-0" to="/demo">Get Started</Link>
                {/* End Info */}
              </div>

              <div className="col-lg-7">
                <div className="position-relative">

                  <figure className="ie-devices-v4-laptop mb-3 mb-sm-0">
                    <img className="img-fluid" src={team} alt="QVR Team" />
                  </figure>

                </div>
              </div>

            </div>
          </div>

          {/* SVG Background Shape */}
          <div className="gradient-half-primary-v6 svg-divider-polygon position-absolute top-0 right-0 bottom-0 left-0">
            <figure className="ie-circles-hero">
              <img className="d-none d-lg-block" src={circlesOrange} alt="Image Description" />
            </figure>
          </div>
          {/* End SVG Background Shape */}
        </div>
        {/* End Hero Section */}

        {/* Features Section */}
        <div class="bg-off-white">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 mb-7 mb-lg-0">
                <div className="row">
                  <div className="col-sm-6">
                    <img className="img-fluid" src={logoTeal} alt="QVR Logo" />
                  </div>
                  <div className="col-sm-6">
                    <Img className="img-fluid" alt="BlueStep Logo" fluid={img.bluelogo.childImageSharp.fluid} />
                  </div>
                </div>
                <p>Together QVR &amp; BlueStep make it easy for your business achive higher profitability &amp; better client outcomes.</p>
                <p style={{ fontSize: `0.5em` }}><span className="text-primary">Better Together</span> QVR utilizes Bluestep&#39;s rock solid <span className="text-primary font-weight-semi-bold">Platform&trade;</span> cloud architecture</p>
              </div>
            </div>

            <div className="mt-lg-n11 mb-7 mb-lg-0">
              {/* SVG Illustration */}
              <figure id="SVGFeatures" className="ie-devices-1">
                <img className="img-fluid" src={devices} aria-hidden="true" alt="" />
              </figure>
              {/* End SVG Illustration */}
            </div>

            <div className="row justify-content-lg-end">
              <div className="col-lg-7 mt-lg-n11">
                <h2 className="h1 font-weight-medium mb-4">We know your industry, because we have worked in it</h2>
                <p>
                  {/* Hi
                  We are two companies working togther to help you accomplish two goals. <br /> */}
                  With over 50 years of experience in the I/DD we’ve come together to help you accomplish two main goals: <br /><br />
                  Save you money on adminstration costs associated with compliance, paperwork, &amp; documentation.<br /> <br/>
                  Help you better serve your clients needs by giving you the tools to make sure your staff are following your behavior plans, &amp; that your behavior plans are effectivly helping your clients reach their goals.
                </p>
              </div>
            </div>
          </div>

          {/* End Features Section */}

          {/* CTA Section */}
          <div className="container space-2">
            <div className="w-lg-85 mx-lg-auto">
              <div id="SVGCTA8" className="card border-0 shadow overflow-hidden p-5">
                <div className="row justify-content-md-start align-items-md-center text-center text-md-left">
                  <div className="col-md-8 col-lg-5 offset-lg-3 mb-3 mb-md-0">
                    <h3 className="h4 text-indigo font-weight-medium mb-0">Revolutionizing the way your bussiness works!</h3>
                  </div>

                  <div className="col-md-4 text-md-right">
                    <Link className="btn btn-primary transition-3d-hover" to="/demo">Free Demo</Link>
                  </div>
                </div>

                <figure className="w-35 position-absolute top-0 left-0">
                  <div className="mt-n11 ml-n11">
                    <img className="img-fluid" src={circle} aria-hidden="true" alt="" />
                  </div>
                </figure>

              </div>
            </div>
          </div>
        </div>
        {/* End CTA Section */}

        <TechieForLife></TechieForLife>

        <TeamDetailSlider></TeamDetailSlider>
        \
      </main>
    </Layout>
  )
}

export default AboutQvr
