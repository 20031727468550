import React from 'react'
import Slider from 'react-slick'
import { Link, useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

//CSS
import '~css/slick.css'

const TeamDetailSlider = () => {

  const img = useStaticQuery(graphql`
    query {
      devon: file(relativePath: { eq: "devon.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 460) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      cole: file(relativePath: { eq: "cole.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 460) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      jared: file(relativePath: { eq: "jared.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 460) {
            ...GatsbyImageSharpFluid
          }
        }
      },
    }
  `)

  const settings = {
    arrows: false,
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 554,
        settings: {
          slidesToShow: 1
        }
      }
    ],
    dotsClass: 'u-slick__pagination u-slick__pagination--white mt-5 mb-0',
    appendDots: dots => (
      <ul style={{ display: `flex` }}> {dots} </ul>
    ),
    customPaging: () => (
      <span></span>
    )
  }
  return (

    <div className="container space-2 text-center">
      <div className="position-relative pt-11 pb-7">
        <div className="position-relative z-index-2 p-2 p-sm-0">
          {/* Title */}
          <div className="w-sm-50 w-md-80 w-lg-70 text-center mx-sm-auto mb-9 z-index-99">
            <span className="btn btn-xs btn-soft-primary btn-pill mb-2">About Us</span>
            <h2 className="font-weight-normal text-white">Executive Team</h2>
            <p className="text-white">We have been where you are. Each of us has years of experience working in and/or owning an I/DD service provider. We were brought together by the understanding that this industry desperatly needed a different type of software. An easy to use tool which could help staff &amp; owners better understand &amp; serve the needs of their clients, and put the emphasis back on the client, instead of on paperwork &amp; documentation.</p>
          </div>
          {/* End Title */}

          {/* Team Detail Slider */}
          <Slider {...settings} className="js-slick-carousel u-slick u-slick--equal-height u-slick--gutters-3 mb-5" style={{ display: `flex` }}>

            {/* Card Block Devon */}
            <div className="js-slide mt-1 mb-2">
              <div className="w-100 bg-white shadow-soft rounded py-6 px-4">
                <Img className="img-fluid w-100 rounded" alt="Devon Millington" fluid={img.devon.childImageSharp.fluid} />
                <h3 className="h6 mb-0">Devon Millington</h3>
                <h3 className="h6 mb-0">CEO</h3>
                <ul class="text-left text-muted">
                  <li>Former VP of one of Utah's Largest I/DD service providers.</li>
                  <li>M.S. in Special Education</li>
                  <li>Board Certified Behavior Analyst (BCBA)</li>
                </ul>
              </div>
            </div>
            {/* End Card Block Devon */}

            {/* Card Block Cole */}
            <div className="js-slide mt-1 mb-2">
              <div className="w-100 bg-white shadow-soft rounded py-6 px-4">
                <Img className="img-fluid w-100 rounded" alt="Cole Morley" fluid={img.cole.childImageSharp.fluid} />
                <h3 className="h6 mb-0">Cole Morley</h3>
                <h3 className="h6 mb-1">CXO</h3>
                <ul class="text-left text-muted">
                  <li>Former owner of I/DD provider</li>
                  <li>Healthcare Administrator</li>
                  <li>Bilingual/certified spanish interpreter</li>
                </ul>
              </div>
            </div>
            {/* End Card Block Cole */}

            {/* Card Block Jared */}
            <div className="js-slide mt-1 mb-2">
              <div className="w-100 bg-white shadow-soft rounded py-6 px-4">
                <Img className="img-fluid w-100 rounded" alt="Jared Whipple" fluid={img.jared.childImageSharp.fluid} />
                <h3 className="h6 mb-0">Jared Whipple</h3>
                <h3 className="h6 mb-1">CTO</h3>
                <ul class="text-left text-muted">
                  <li>Senior Engineer for multi-million dollar supplement company</li>
                  <li>M.A. in Family Therapy</li>
                  <li>Advanced training in ABA</li>
                </ul>
              </div>
            </div>
            {/* End Card Block Jared */}

          </Slider>
          {/* End Team Detail Slider */}

          {/* Small CTA */}
          <p className="text-white">
            See QVR in action.
            <Link className="text-warning font-weight-medium" to="/demo"> Request a Demo
              <span className="btn btn-icon btn-sm btn-white rounded-circle ml-2">
                <span className="fas fa-arrow-right btn-icon__inner"></span>
              </span>
            </Link>
          </p>
          {/* End Small CTA */}
        </div>

        {/* Background */}
        <div className="gradient-half-primary-v4 position-absolute top-0 right-0 bottom-0 left-0 rounded-lg mx-sm-7"></div>
      </div>
    </div>

  )
}

export default TeamDetailSlider
